.hosting-action-buttons > div
  display: flex
  justify-content: flex-start

.total-select-badge
  font-size: 1em
  margin-right: ($margin / 2)
  background: $medium-grey

.test-data-section
  margin-left: 16px
  border-radius: 10px
  padding-left: 10px
  width: 100%
  margin-bottom: 10px

  .section-header
    display: flex
    flex-direction: row
    align-items: flex-end
    border-bottom: 1px solid gray
    margin-bottom: 10px
    padding-bottom: 10px

    .actions
      font-weight: bolder
      color: white
      padding-top: 0
      padding-bottom: 0
      align-items: self-start
      font-size: 12px

    .remove
      background: #dc3545

  .section-title
    font-weight: bold
    padding-right: 10px
    line-height: 25px
    margin-bottom: 0px

.add-test-data-env-section
  background: #efefef
  border-radius: 10px
  width: 98%

.new-test-case-modal
  display: flex
  justify-content: center

  button
    border: 2px solid $grey
    border-radius: 2px
    background: $white
    padding: $padding
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    i.bi
      font-size: 2.5em

    .button-title
      font-weight: 600

    &:hover
      border-color: turquoise

    &:not(:last-child)
      margin-right: $margin

a.read-only-input
  display: inline-block
  background: #efefef
  border: 1px solid #e2e2e2
  border-radius: 4px
  width: 100%
  padding: 10px 5px
  overflow-x: hidden
  text-overflow: ellipsis

.hosting-select-all
  position: relative
  width: 85px
  display: inline-block
  margin-right: $margin

.view-type
  border: 1px solid $medium-grey
  border-radius: 4px

  button
    i
      pointer-events: none

    &.selected
      background: darken($medium-grey, 5%)

.scenarios
  &.table
    display: block
    margin: 0

    .rt-td
      display: flex
      align-items: center

      .checkbox-container
        margin-top: -20px

.hosting-wrapper
  .notification .form-group, .jira-fields-wrapper .form-group
    margin-bottom: 0

    label
      margin-bottom: 0

  .notification-field-checkbox-group
    margin-top: 9px

.test-data
  display: inline-block
  padding-right: 10px
  width: 20%

.test-data-group
  position: relative
  border-radius: 16px
  display: flex
  border: 2px solid #5f60ff
  flex-wrap: wrap
  padding: 10px 32px 10px 0px
  margin: 5px
  label
    text-transform: none
    margin-right: 8px
    margin-bottom: 0

.test-data-list
  display: flex
  flex-wrap: wrap

  .test-data-remove
    position: absolute
    top: 0
    right: 0
    background-color: #fff
    border: none
    border-radius: 50%
    color: red
    cursor: pointer
    font-size: 48px
    font-weight: bold
    height: 32px
    line-height: 42px
    margin-left: auto
    text-align: center
    width: 32px
    transform: rotate(45deg)
    padding: 0
    &:disabled
      background-color: white
      border-color: #6d6dfa59
      color: #6d6dfa59
      cursor: not-allowed

.test-data-add
  background-color: #fcfcfc
  border: none
  border-radius: 50%
  color: #00ff00
  cursor: pointer
  font-size: 48px
  font-weight: bold
  height: 50px
  line-height: 42px
  margin-left: auto
  text-align: center
  width: 50px
  &:disabled
    background-color: white
    color: #6d6dfa59
    cursor: not-allowed

.test-data-add__btn-right
  text-align: right

.hosting-panel
  display: flex
  align-items: center
  justify-content: space-between
  .left-panel
    display: flex

.testcases-list__heading
  margin-bottom: 1rem
  text-shadow: 1px 0 #000000
  letter-spacing: 1px
  font-weight: bold

.testcase-list-item
  position: relative
  background: #fcfcfc
  border-left: 4px solid #efefef
  padding: 10px
  border-radius: 4px
.testcase-list-item:not(:last-child)
  margin-bottom: 10px

.bulk-modal-form-group
  display: flex
  align-items: center
  > label
    font-weight: bolder
    font-size: 14px
    text-transform: none
    margin-right: 10px
    margin-bottom: 0
  > select
    width: auto

.form-group-inline
  display: flex
  align-items: center

.global-fields__content
  background-color: #efefef
  padding: 8px 8px 8px 0px
  border-radius: 10px
  margin-bottom: 20px

.global-fields__controls
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: baseline
  input
    margin-right: 10px
  select
    margin-right: 10px
.choices-group
  display: flex
  margin-bottom: 10px
  align-items: baseline
  flex-wrap: wrap
  .choices
    align-items: baseline
    display: flex
    &:not(:last-child)
      margin-bottom: 10px
    .choices-feedback
      margin-right: 5px
    input
      margin-right: 5px

// target the trash and the checkmark icons
[class*=" bi-trash"]::before
      vertical-align: middle
[class*=" bi-check"]::before
      vertical-align: middle

.default-radio
  display: none

.default-label
  display: inline-block
  padding: 5px 9px
  border: 1px solid green
  border-radius: 4px
  cursor: pointer
  color: green
  font-size: 14px
  text-align: center
  margin-bottom: 0px
  margin-right: 4px

  .default-radio:checked + &
    background-color: green
    color: white

  & .bi-check
    color: green

  .default-radio:checked + & .bi-check
    color: white

.inputs-container
  display: flex
  align-items: baseline
  .input-select-container
    margin-right: 10px
    padding-right: 0px
    padding-left: 0px

.select-editable
  position: relative
  width: 100%
  height: 18px

  & select
    position: absolute
    top: -3px
    left: 0px
    width: 100%

  & input
    position: absolute
    top: -3px
    left: 0px
    width: 95%

  & select:focus, & input:focus
    outline: none

.test-data-file-section
  display: flex
  align-items: center

.test-iteration-file-section
  display: flex
  align-items: center
  margin-bottom: 10px

.select-container
  display: flex
  flex-direction: column
  margin-bottom: 10px

.select-label
  margin-bottom: 5px

.choose-file-button
  margin-left: 10px
  margin-bottom: 10px

.file-name
  margin-bottom: 10px
  margin-left: 10px

.warning-text
  margin-bottom: 10px

.icon-button-disabled
  pointer-events: none !important
  cursor: not-allowed !important
  opacity: 0.5 !important

  &:hover
    cursor: not-allowed !important

.confirm-close-dialog
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  background-color: white
  padding: 20px
  border-radius: 8px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
  z-index: 1000
  width: 300px
  text-align: center

  & p
    margin-bottom: 20px
    font-size: 16px
    color: #333

  & .btn
    margin: 0 10px
    padding: 10px 20px
    font-size: 14px

  & .btn-primary
    background-color: #5f60ff
    color: white
    border: none
    border-radius: 4px
    cursor: pointer

  & .btn-primary:hover
    background-color: #0069d9
    border-color: #0062cc

  & .btn-secondary
    background-color: #6c757d
    color: white
    border: none
    border-radius: 4px
    cursor: pointer

  & .btn-secondary:hover
    background-color: #5a6268

.column-title
  font-size: 1rem
  font-weight: bold

.column-invalid-feedback-block
  position: absolute
  top: 42px

.tag-filter
  width: 50%
  margin-bottom: 16px
