.modal-wrapper
  position: fixed
  display: flex
  align-items: center
  justify-content: center
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 100000

  .modal-overlay
    background: rgba(0, 0, 0, .25)
    width: 100%
    height: 100%
    position: fixed
    top: 0
    left: 0
    padding: 40px

  .modal-content-wrapper
    overflow-y: scroll
    overflow-x: hidden
    max-height: 400px

  .modal-body
    background: $white
    border-radius: 4px
    max-width: 1080px
    max-height: 90%
    padding: 0
    display: flex
    flex-direction: column
    position: relative
    &.modal--wide
      max-width: 90%
      width: 90%
    .modal-loading
      position: absolute
      display: flex
      background: rgba(0, 0, 0, .25)
      width: 100%
      height: 100%
      z-index: 1000
      justify-content: center

    .modal-title
      display: flex
      justify-content: space-between
      background: $blue
      padding: $padding

      h4
        color: $white
        margin: 0
        font-weight: 100

      .modal-close-button
        border: none
        background: transparent

        svg
          margin: 0
          width: 20px
          padding: 0

    .modal-content
      padding: $padding
      border: none
      border-radius: 0
      box-shadow: none
      overflow: scroll

    .modal-footer
      padding: $padding
      background: $medium-grey
      display: flex
      border: 0
      z-index: 8
      width: 100%
      bottom: 0

      .button-wrapper
        text-align: center
        flex-grow: 1
        flex-basis: 0

        button
          border: none
          background: transparent
          font-weight: 600
          color: $blue

          &.disabled
            color: #b0b0b0
            cursor: not-allowed
        &.delete-btn
          button
            color: $red
            font-weight: 600
            &:hover
              color: darken($red, 20%)
  .modal--small
    max-width: 600px !important
